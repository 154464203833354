@import './bootstrap_custom';
@import './variables';
@import './strip';

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar-brand {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 120px;
  line-height: 30px;

  @media (max-width: 480px) {
    height: 48px;
  }

  img {
    height: 80px;

    @media (max-width: 480px) {
      height: 30px;
    }
  }
}

.navbar {
  width: 100%;
}

.navbar-container {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  height: 120px;

  @media (max-width: 480px) {
    height: 48px;
  }
}

footer {
  display: flex;
  align-items: center;
  border-top: 1px solid $blue;
  height: 40px;
}

a {
  color: #1d7a8c !important;
}

.main-container {
  flex: 1;
  padding: 0 1rem;
}

// TODO: Find another way to override this value
.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

.text-primary {
  color: $primary !important;
}

.border-primary {
  border-color: $primary !important;
}

.devise-form-wrapper {
  display: flex;
  justify-content: center;
  color: #555555;

  h2 {
    color: #777777;
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 2rem;
  }

  .devise-form {
    max-width: 400px;
    width: 100%;
  }
}

.download-button {
  height: 56px;
  width: 180px;
}
